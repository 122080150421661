import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Loading from './components/Loading'; // Import your Loading component here

// Lazy load App component
const App = lazy(() => import('./App'));

// Create root using createRoot
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}> {/* Use the Loading component here */}
      <App />
    </Suspense>
  </React.StrictMode>
);
