import React, { useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';
import './Loading.css'; // Ensure you have your updated CSS

const Loading = () => {
  const [showMessage, setShowMessage] = useState(true);
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false); // Hide message after 4 seconds
      setShowConfetti(false); // Hide confetti after 4 seconds
    }, 4000); // Duration of 4 seconds (4000ms)
    
    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  return (
    <div className="loading-container">
      {showConfetti && (
        <ReactConfetti
          recycle={false} // Stops after 4 seconds
          numberOfPieces={1100} // Number of confetti pieces
        />
      )}
      <div className="spinner"></div> {/* Spinner for loading effect */}
      {showMessage && (
        <div className="new-year-message">
          Welcome to 2025! A year of growth and new opportunities!
        </div>
      )}
    </div>
  );
};

export default Loading;
